import React, {memo} from 'react';
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {arrowDownIcon} from '../../img/svg-icons';
import './styles.scss';

const PageinationArabic = () => {
  const {t, i18n} = useTranslation();
};

const Pagination = ({activePage, totalCount, itemsPerPage, showNext, onChange, className}) => {
  const {t, i18n} = useTranslation();
  let paginationItemsCount = Math.ceil(totalCount / itemsPerPage);
  if (showNext && activePage >= paginationItemsCount) {
    paginationItemsCount = activePage + 1;
  }

  const PaginationItems = paginationItemsCount => {
    let itemCount = [];
    let leftDots = false;
    let RightDots = false;

    const filterFunc = item => {
      if (activePage <= 4 && item <= 6) return true;
      if (activePage > paginationItemsCount - 4 && item > paginationItemsCount - 6) return true;
      return (
        item === activePage ||
        item === activePage - 1 ||
        item === activePage + 1 ||
        item === activePage - 2 ||
        item === activePage + 2
      );
    };

    if (paginationItemsCount > 7) {
      if (activePage > 4) leftDots = true;
      if (activePage <= paginationItemsCount - 4) RightDots = true;

      if (activePage < paginationItemsCount - 4 && activePage > 4) {
        itemCount = [
          1,
          ...Array.from(new Array(paginationItemsCount).keys(), x => x + 1).filter(filterFunc),
          paginationItemsCount,
        ];
      }
      if (activePage >= paginationItemsCount - 4 && activePage > 4) {
        itemCount = [
          1,
          ...Array.from(new Array(paginationItemsCount).keys(), x => x + 1).filter(filterFunc),
          paginationItemsCount,
        ];
      }
      if (activePage <= paginationItemsCount - 4 && activePage <= 4) {
        itemCount = [
          ...Array.from(new Array(paginationItemsCount).keys(), x => x + 1).filter(filterFunc),
          paginationItemsCount,
        ];
      }
    } else {
      itemCount = Array.from(new Array(paginationItemsCount).keys(), x => x + 1).filter(item => item !== 0);
    }

    return (
      <div className="pagination-items" key={uuidv4()}>
        {PaginationLeftOrRightItem('left')}
        {/* @ts-ignore */}
        {[...new Set(itemCount)].map(item => {
          if (item === 0) return null;
          if (
            activePage < paginationItemsCount - 4
              ? item === activePage - 2 && leftDots
              : item ===
                  paginationItemsCount -
                    (paginationItemsCount > 8 && activePage === paginationItemsCount - 4 ? 6 : 5) && leftDots
          ) {
            return PaginationDotsItem();
          }
          if (activePage >= 4 ? item === activePage + 2 && RightDots : item === 6 && RightDots) {
            return PaginationDotsItem();
          }
          return PaginationItem(item);
        })}
        {PaginationLeftOrRightItem('right')}
      </div>
    );
  };

  const PaginationDotsItem = () => {
    return (
      <div key={uuidv4()} className={`pagination-item noselect`}>
        <span className="">...</span>
      </div>
    );
  };

  const PaginationLeftOrRightItem = side => {
    return (
      <React.Fragment key={uuidv4()}>
        {((side === 'left' && activePage > 1) || (side !== 'left' && activePage < paginationItemsCount)) && (
          <div
            className={`pagination-item noselect`}
            onClick={() => {
              side === 'left'
                ? activePage > 1 && onChange(activePage - 1)
                : activePage < paginationItemsCount && onChange(activePage + 1);
            }}
          >
            <span className={`icon ${side}-icon`}>{arrowDownIcon}</span>
          </div>
        )}
      </React.Fragment>
    );
  };

  const PaginationItem = number => {
    return (
      <div
        key={uuidv4()}
        className={`pagination-item ${activePage === number ? 'active' : ''} noselect`}
        onClick={() => onChange(number)}
      >
        <span className="">{number}</span>
      </div>
    );
  };

  return <div className={`pagination ${className ? className : ''} `}>{PaginationItems(paginationItemsCount)}</div>;
};

export default memo(Pagination);
