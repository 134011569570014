import React, {memo, useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {CSVLink} from 'react-csv';
import {navigate} from 'gatsby';
import Pagination from '../../components/pagination';
import {downloadIcon} from '../../img/svg-icons';
import {getDateAndTime} from '../../helpers';
import './styles.scss';

const options = [5, 15, 25, 30, 50];

const HistoryTable = ({list, total, sort, setSort, page, setPage, limit, setLimit}) => {
  const {t, i18n} = useTranslation();
  const [showMenu, setShowMenu] = useState(false);

  const handleRowOptionClick = val => {
    setLimit(val);
    setPage(1);
    setShowMenu(false);
  };

  const handleChangeSortByDate = () => {
    setSort({sortBy: 'scanned_at', type: sort.type === 'desc' && sort.sortBy === 'scanned_at' ? 'asc' : 'desc'});
  };

  const handleChangeSortBySource = () => {
    setSort({sortBy: 'source', type: sort.type === 'desc' && sort.sortBy === 'source' ? 'asc' : 'desc'});
  };

  const handleChangePage = val => {
    setPage(val);
  };

  const handleDownloadCSV = fullRes => {
    const csv = [['name', 'url', 'status', 'category']];
    if (fullRes) {
      fullRes.map((item, index) => {
        csv[index + 1] ? csv[index + 1].push(item.name) : (csv[index + 1] = [item.name]);
      });
      fullRes.map((item, index) => {
        csv[index + 1] ? csv[index + 1].push(item.url) : (csv[index + 1] = [item.url]);
      });
      fullRes.map((item, index) => {
        csv[index + 1] ? csv[index + 1].push(item.status) : (csv[index + 1] = [item.status]);
      });
      fullRes.map((item, index) => {
        csv[index + 1] ? csv[index + 1].push(item.category) : (csv[index + 1] = [item.category]);
      });
    }
    return csv;
  };

  return (
    <>
      <div className=" w-100 history_table mx-auto">
        <div className="row w-100 table-header">
          <div className={`primary-text col-md-5 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`} onClick={handleChangeSortBySource}>
            {t('Source')}
            <span className={`sorting-arrow ${sort.sortBy === 'source' ? 'active' : ''}`}>
              {sort.type === 'desc' ? '↓' : '↑'}
            </span>
          </div>
          <div className={`primary-text col-md-3 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`} onClick={handleChangeSortByDate}>
            {t('Scan-Date')}
            <span className={`sorting-arrow ${sort.sortBy === 'scanned_at' ? 'active' : ''}`}>
              {sort.type === 'desc' ? '↓' : '↑'}
            </span>
          </div>
          <div className="primary-text col-md-4"></div>
        </div>
        {list.map(item => (
          <div className="cursor-pointer row w-100 table-body mb-2" key={item.id}>
            <div
              className={`secondary-text text-truncate col-sm-6 col-lg-5 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
              onClick={() => navigate(`${i18n.language === 'ar' ? '/ar' : ''}/my-account/history/${item.id}/`)}
            >
              {item.source}
            </div>
            <div
              className={`secondary-text col-sm-6 col-lg-3 text-sm-lg-right ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
              onClick={() => navigate(`${i18n.language === 'ar' ? '/ar' : ''}/my-account/history/${item.id}/`)}
            >
              {getDateAndTime(item.scanned_at)}
            </div>
            <div className="col-lg-4 text-right">
              <span
                onClick={() => navigate(`${i18n.language === 'ar' ? '/ar' : ''}/my-account/history/${item.id}/`)}
                className={`status-badge ${
                  item.status === 'success' ? 'success' : item.status === 'prending' ? 'primary' : 'fail'
                }`}
              >
                {item.status}
              </span>
              {item.result && (
                <span title="Download CSV">
                  <CSVLink
                    data={handleDownloadCSV(item.result)}
                    className="download-btn my-auto mx-4"
                    filename={`CandidateChecker-${item.source}-report.csv`}
                  >
                    {downloadIcon}
                  </CSVLink>
                </span>
              )}
              <span
                style={{color: '#7879f1', marginLeft: '20px'}}
                onClick={() => navigate(`${i18n.language === 'ar' ? '/ar' : ''}/my-account/history/${item.id}/`)}
              >
                {t('see-result')} {i18n.language === 'ar' ? '' : '→'}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="table-footer">
        <div className="table-footer-left d-flex">
          <span className="row-num-lable">{t('Rows-per-page')}</span>
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" onClick={() => setShowMenu(!showMenu)}>
              {limit}
            </button>
            {showMenu && (
              <div className="dropdown-menu">
                {options.map(option => (
                  <div key={option} className="dropdown-item" onClick={() => handleRowOptionClick(option)}>
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {Math.ceil(total / limit) > 1 && (
          <div className="table-footer-right">
            <Pagination
              activePage={page}
              onChange={handleChangePage}
              totalCount={Math.ceil(total / limit)}
              itemsPerPage={1}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default memo(HistoryTable);
