import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import i18next, {t} from 'i18next';
import {getRequestsHistory} from '../../requests';
import HistoryTable from '../historyTable';
import loop from '../../img/icons/search-icon.svg';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const History = () => {
  const [result, setResult] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [source, setSource] = useState('');
  const [sort, setSort] = useState({sortBy: 'scanned_at', type: 'desc'});
  const [startLoader, setStartLoader] = useState(true);
  const [change, setChange] = useState(false);
  const {t, i18n} = useTranslation();
  const getList = useCallback(
    async data => {
      setLoading(true);
      const resp = await getRequestsHistory(data);
      setResult(resp.result || []);
      setTotal(resp.total || 0);
      setLoading(false);
      setStartLoader(false);
    },
    [getRequestsHistory],
  );

  useEffect(() => {
    const sortBy = Object.values(sort);
    getList({
      page,
      limit,
      source,
      order: {
        [sortBy[0]]: sortBy[1],
      },
    });
  }, [getList, page, limit, source, sort]);

  const handleClick = useCallback(() => {
    setSource(ref && ref.current ? ref.current.value : '');
    setPage(1);
    setChange(true);
  }, [ref]);

  const handleCaptureOnEnter = useCallback(
    e => {
      if (e.key === 'Enter') {
        setSource(ref && ref.current ? ref.current.value : '');
        setPage(1);
        setChange(true);
      }
    },
    [ref],
  );
  return (
    <div className="w-100">
      {startLoader ? (
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only"></span>
        </div>
      ) : (result && result.length > 0) || change ? (
        <div className="card-container">
          <div className="card">
            <div className="table-search-container">
              <input
                className={`input is-rounded inputBar ${i18n.language === 'ar' ? "typing-rl" : ''}`}
                type="text"
                placeholder={i18n.language === 'ar' ? 'يبحث' : 'Search...'}
                onKeyPress={handleCaptureOnEnter}
                maxLength={320}
                ref={ref}
              />
              <img src={loop} onClick={handleClick} className="loop-icon" />
            </div>
            {loading ? (
              <div class="spinner-border mx-auto my-4 text-secondary" role="status">
                <span class="sr-only"></span>
              </div>
            ) : result.length < 1 ? (
              <div className="primary-text w-100 text-center">{t('nothing-to-show')}</div>
            ) : (
              <HistoryTable
                list={result}
                total={total}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                sort={sort}
                setSort={setSort}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="primary-text w-100 text-center">{t('nothing-to-show')}</div>
      )}
    </div>
  );
};

export default memo(History);
