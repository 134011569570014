import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import {UserContext} from '../../context/user-context';
import {getDate, getDateAndTime} from '../../helpers';
import Modal from '../../components/modal';
import {getUser, paymentCancelResume} from '../../requests';
import CustomProgress from '../custom-progress';
import './styles.scss';

export default ({}) => {
  const {t, i18n} = useTranslation();
  const {user, setUser} = useContext(UserContext);
  const [confirmationPopup, setConfirmationPopup] = useState({
    show: false,
    type: '',
  });
  const [loading, setLoading] = useState(false);

  const getUserInfo = async () => {
    const userInfo = await getUser();
    userInfo && setUser(userInfo);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const handleCancel = async () => {
    await paymentCancelResume(user.package_data.product_type, true);

    setConfirmationPopup({show: false, type: ''});
    getUserInfo();
  };
  const handleResume = async () => {
    const resp = await paymentCancelResume(user.package_data.product_type, false);

    setConfirmationPopup({show: false, type: ''});
    getUserInfo();
  };

  return user && user.id ? (
    <div className="profile-card row position-relative">
      <div className="left-section col-lg-5">
        <div className="d-block d-lg-flex m-auto">
          <img src={user.picture} alt={user.name} />
        </div>
        <div className={`name-info ${i18n.language === 'ar' ? 'text-right' : ''}`}>
          <div className="title">{user.name}</div>
          <div>{user.email}</div>
        </div>
      </div>
      <div className="col-lg-7 right-section my-auto">
        <div className="progress-container mt-2">
          <span className={i18n.language === 'ar' ? 'text-right mr-2 ml-50' : 'text-left'}>
            {t('Registration-date')}
          </span>
          <div style={{textTransform: 'capitalize'}}>{getDateAndTime(user.created_at)}</div>
        </div>
        {user.package_data && typeof user.package_data.limit === 'number' && typeof user.request_left === 'number' && (
          <>
            {user.package_data.name && (
              <div className="progress-container mt-2">
                <span className={i18n.language === 'ar' ? 'text-right mr-2 ml-50' : 'text-left'}>
                  {t('Current-plan')}
                </span>
                <div style={{textTransform: 'capitalize'}}>
                  {user.package_data.name.toLowerCase() === 'free'
                    ? 'Free'
                    : `Discover ${user.package_data.name.replace('annually', 'annual')}`}
                </div>
              </div>
            )}
            <div className="progress-container mt-2">
              <span className={i18n.language === 'ar' ? 'text-right mr-2 ml-50' : 'text-left'}>{t('Checks-used')}</span>
              {user.package_data.limit === 500 ? (
                user.package_data.limit - user.request_left
              ) : (
                <CustomProgress
                  percent={((user.package_data.limit - user.request_left) / user.package_data.limit) * 100 || 0}
                  text={`${+(user.package_data.limit - user.request_left) || 0} ${t('checks-out-of')} ${
                    user.package_data.limit
                  }`}
                />
              )}
            </div>
          </>
        )}
        {user.package_data.name !== 'LTD' && user.package_data.name !== 'free' && (
          <div className="d-flex cancel-section">
            {!user.cancel_at ? (
              <span
                className="ml-auto clickable-text"
                onClick={() =>
                  setConfirmationPopup({
                    show: true,
                    type: 'cancel',
                  })
                }
              >
                {t('cancel-subscription-renewal')}
              </span>
            ) : (
              <div className="ml-auto">
                <span>
                  {t('subscription-will-be-canceled-on')} {getDate(user.cancel_at)}.
                </span>{' '}
                {user.package_data.product_type === 'stripe' && (
                  <span
                    className="clickable-text"
                    onClick={() =>
                      setConfirmationPopup({
                        show: true,
                        type: 'resume',
                      })
                    }
                  >
                    {t('resume-subscription')}
                  </span>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {confirmationPopup && confirmationPopup.show && (
        <Modal
          isOpen={confirmationPopup.show}
          modalToggle={() => setConfirmationPopup({show: false, type: ''})}
          title={
            confirmationPopup.type === 'cancel' ? t('confirm-cancel-subscription') : t('confirm-resume-subscription')
          }
          onButtonClick={() => (confirmationPopup.type === 'cancel' ? handleCancel() : handleResume())}
          buttonText={
            loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              t('confirm')
            )
          }
          danger
        />
      )}
    </div>
  ) : (
    <></>
  );
};
