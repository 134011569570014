import React, {memo} from 'react';
import { useTranslation } from 'react-i18next';
import DeleteAccount from '../../components/deleteAccount';
import History from '../../components/history';
import Layout from '../../components/layout';
import ProfileCard from '../../components/profile-card';

const Account = () => {
  const {t} = useTranslation();
  return (
    <Layout title={t('my-account')} showLogo>
      <ProfileCard />
      <History />
    </Layout>
  );
};

export default memo(Account);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
